import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { FENCE_TYPE_GATE_SELF_SUPPORTING } from 'FenceTypesConstants';
import { COUNTERWEIGHT_NON_STANDARD } from '../Input/Counterweight';
import Poles, {
  POLES_GSS_ONE,
  POLES_GSS_TWO,
  POLES_GSS_THREE,
} from '../Input/Poles';
import Holder, {
  HOLDER_FORM_NAME,
  HOLDER_NO,
} from '../Input/Holder';
import GearRail from '../Input/GearRail';
import LockAndHandrail from '../Input/LockAndHandrail';
import FullLength from '../Input/FullLength';
import FormActions from '../Input/FormActions';
import GateSelfSupportingResponse from './GateSelfSupportingResponse';
import GateDirection from '../Input/GateDirection';
import Slide from '../Input/Slide';
import invoke from '../../lambda';
import { COLOR_HDG } from '../OrderForm/OrderFormFenceColor';

const GateSelfSupporting = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      color: form.color,
      height: form.values.height || '',
      width: form.values.width || '',
      poles: form.values.poles || '',
      holder: form.values.holder || '',
      gearRail: form.values.gearRail || '',
      fullLength: form.values.fullLength || '',
      lockAndHandrail: form.values.lockAndHandrail || '',
      gateDirection: form.values.gateDirection || '',
      slide: form.values.slide || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const res = await invoke({
        amount: form.quantity,
        model: 'PANEL 2DS',
        type: 'samonosna',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({ height, width }) => {
      const errors = {};

      if (!height) {
        errors.height = 'Wymagana wartość';
      } else if (height < 500) {
        errors.height = 'Minimalna wysokość to 500mm.';
      } else if (height > 2500) {
        errors.height = 'Maksymalna wysokość to 2500mm.';
      }
      if (!width) {
        errors.width = 'Wymagana wartość';
      } else if (width < 1000) {
        errors.width = 'Minimalna szerokość to 1000mm.';
      } else if (width > 10000) {
        errors.width = 'Maksymalna szerokość to 10000mm.';
      }

      return errors;
    },
  });
  useEffect(() => {
    if (formik.values.poles === POLES_GSS_THREE || formik.values.poles === POLES_GSS_TWO || formik.values.poles === POLES_GSS_ONE) {
      formik.setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  }, [formik.values.poles]);
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.height && formik.errors.height}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`width__${form.id}`}
            name="width"
            value={formik.values.width}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.width && formik.errors.width}</span>
        </div>
        <Poles
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_GATE_SELF_SUPPORTING}
          value={formik.values.poles}
        />
        <Holder
          formId={form.id}
          onChange={formik.handleChange}
          poles={formik.values.poles}
          value={formik.values.holder}
        />
        <GearRail formId={form.id} onChange={formik.handleChange} value={formik.values.gearRail} />
        <FullLength
          formId={form.id}
          onChange={formik.handleChange}
          counterweight={COUNTERWEIGHT_NON_STANDARD}
          value={formik.values.fullLength}
        />
      </div>
      <div className="formInputs">
        <LockAndHandrail
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.lockAndHandrail}
        />
        <GateDirection
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.gateDirection}
        />
        <Slide
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.slide}
        />
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <GateSelfSupportingResponse values={formik.values} hdg={form.color === COLOR_HDG} response={form.response} isPdf={false} />}
    </form>
  );
};

GateSelfSupporting.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GateSelfSupporting;
