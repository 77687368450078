import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import CloseProject from 'components/CloseProject';
import Footer from 'components/Footer';
import GeneralComments from 'components/GeneralComments';
import OrderForm from 'components/OrderForm/OrderForm';
import AddNextForm from 'containers/AddNextForm';
import GettingStarted from 'containers/GettingStarted';
import PdfBtn from 'containers/Pdf/PdfBtn';

import SignIn from 'Pages/SignIn/SignIn';

import Header from 'Pages/shared/header';
import ProductTable from './ProductTable';

const MaterialCard = () => {
  const { app: appState } = useSelector((s) => s);
  const {
    forms, authenticated, zamowienie, blocked,
  } = appState;

  const active = sessionStorage.getItem('zamowienieActive') === '1';

  const formsRendered = forms.map((form) => (
    <OrderForm key={form.id} forms={forms} form={form} />
  ));

  const productsTable = zamowienie?.map((product) => (
    <ProductTable product={product} />
  ));

  const disableZamowienie = async () => {
    const zamowieniaId = sessionStorage.getItem('zamowieniaId');
    await axios.post(
      `/production-materials-manually-calculated/${zamowieniaId}/set-active/0`,
    );
  };

  const enableZamowienie = async () => {
    const zamowieniaId = sessionStorage.getItem('zamowieniaId');
    await axios.post(
      `/production-materials-manually-calculated/${zamowieniaId}/set-active/1`,
    );
  };

  if (!authenticated) {
    return (
      <>
        <main>
          <SignIn />
        </main>
        <Footer />
      </>
    );
  }
  return (
    <>
      <main>
        <Header />
        <GettingStarted />
        <hr />
        {zamowienie && (
          <section>
            <div className="container-xl">
              <div className="row">
                <div className="col">
                  <h4>Obecne produkty w zamówieniu: </h4>
                </div>
              </div>
            </div>
          </section>
        )}
        {zamowienie && productsTable}
        <section>
          <div className="container-xl">
            <div className="row">
              <div className="col">
                {zamowienie && active && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={disableZamowienie}
                    >
                      Dezaktywuj zamówienie.
                    </button>
                  </div>
                )}
                {zamowienie && !active && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={enableZamowienie}
                    >
                      Aktywuj zamówienie.
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <hr />
        {!blocked && formsRendered}
        <AddNextForm />
        <GeneralComments />
        <PdfBtn />
        <CloseProject />
      </main>
      <Footer />
    </>
  );
};

export default MaterialCard;
