import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { formBase } from './AddNextForm';
import './GettingStarted.css';

const GettingStarted = ({
  addNextForm, setOrderNumber, setZamowienie, setBlocked, orderNumber,
}) => {
  const forms = useSelector((state) => state.app.forms);
  const [orderId, setOrderId] = useState(null);
  const [zamowieniaId, setZamowieniaId] = useState(null);
  const [calculationReady, setcalculationReady] = useState(false);
  const [urlReady, seturlReady] = useState(false);
  const [blockChange, setblockChange] = useState(false);

  const handleChange = async (e) => {
    const number = e.target.value;
    if (number.length >= 5) {
      try {
        const { data } = await axios.get(`/zamowienia/${number}/get-order-id`);
        setOrderId(data?.order?.id);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      try {
        const response = await axios.get(`/production-materials-manually-calculated/${number}`);
        if (response.status === 200 && response.data.products.length > 0) {
          sessionStorage.setItem('zamowienieActive', response.data.products[0].active);
          if (response.data.products[0].header !== '') {
            setcalculationReady(true);
          } else if (response.data.products[0]?.url === '') {
            seturlReady(true);
          }
          setZamowienie(response.data.products);
          if (response.data.products[0]?.url !== '') {
            setBlocked(true);
            setblockChange(true);
          } else {
            setblockChange(false);
            setBlocked(false);
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      setZamowieniaId(number);
      sessionStorage.setItem('zamowieniaId', number);
    } else {
      setOrderId(null);
      setcalculationReady(false);
    }
    setOrderNumber(number);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (orderNumber && !forms.length) {
      addNextForm(formBase());
    }
  };

  const [urlSaved, seturlSaved] = useState('Zapisz pdf');
  const [processing, setProcessing] = useState(false);
  const [openUrl, setopenUrl] = React.useState(false);
  const handleOpenUrl = () => setopenUrl(true);
  const handleCloseUrl = () => setopenUrl(false);

  const recalculate = (e) => {
    e.preventDefault();
    axios.post(`/zamowienia/${orderId}/recalculate-production-materials`);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    sessionStorage.setItem('std', '0');
    setOpen(false);
  };

  const handleNo = () => {
    sessionStorage.setItem('std', '1');
    setOpen(false);
  };

  const inputRef = useRef();

  const addProtocolIfMissing = (url) => {
    // Check if the URL starts with a protocol (http:// or https://)
    if (!/^(https?:\/\/)/i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  const handleSaveUrl = async () => {
    setProcessing(true);
    const u = addProtocolIfMissing(inputRef.current.value);
    let urlParsed = URL;
    try {
      urlParsed = new URL(u);
    } catch {
      seturlSaved('Błędny url.');
      setProcessing(false);
      return;
    }
    const payload = {
      url: urlParsed,
    };
    try {
      const { status } = await axios.put(`/production-materials-manually-calculated/${zamowieniaId}/url`,
        payload);
      switch (status) {
        case 204:
          seturlSaved('Zapisano');
          setProcessing(false);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setopenUrl(false);
          break;
        default:
          seturlSaved('Wystąpił błąd');
          setProcessing(false);
      }
    } catch {
      seturlSaved('Wystąpił błąd');
      setProcessing(false);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const Popup = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {blockChange && (
          <Link to="/">
            <button className="btn btn-danger" type="button" onClick={handleClose}>Zamówienie zablokowane do edycji ponieważ został już przypisany link do pdf</button>
          </Link>
        )}
        {!blockChange && (
          <>
            <b>Czy zamówienie jest niestandardowe?</b>
            <div className="popup-buttons">
              <button className="btn btn-danger" type="button" onClick={handleYes}>TAK (dezaktywuj zamówienie po wygenerowaniu PDF)</button>
              <button className="btn btn-danger" type="button" onClick={handleNo}>NIE (wygeneruj kartę w linku)</button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );

  const PopupURL = () => (
    <Modal
      open={openUrl}
      onClose={handleCloseUrl}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <b>Wprowadź link do pdf:</b>
        <div className="popup-buttons">
          <input
            type="text"
            className="form-control"
            placeholder="Wpisz link do pdf"
            id="url"
            name="width"
            autoComplete="off"
            ref={inputRef}
            autoFocus
            required
          />
          <button
            className="btn btn-danger"
            type="button"
            onClick={handleSaveUrl}
            disabled={processing}
          >
            <span>{urlSaved}</span>
            {processing && <FontAwesomeIcon className="spinner" icon={faSpinner} size="3x" />}
            {!processing && <FontAwesomeIcon icon={icon} size="3x" />}
          </button>
        </div>
      </Box>
    </Modal>
  );

  return (
    <section>
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <form id="gettingStartedForm" onSubmit={handleSubmit}>
              <div className="container-xl">
                <div className="row">
                  <div className="col-10" id="gettingStartedFormLeftCol">
                    <div className="form-group">
                      <label htmlFor="orderNumber">Wpisz numer zlecenia</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Wpisz numer zlecenia"
                        id="orderNumber"
                        name="width"
                        autoComplete="off"
                        value={orderNumber || ''}
                        onChange={handleChange}
                        autoFocus
                        minLength={5}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2" id="gettingStartedFormRightCol">
                    {orderId
                      ? (
                        <>
                          <button
                            className="btn btn-danger"
                            id="gettingStartedBtn"
                            type="submit"
                            tabIndex={-1}
                            onClick={handleOpen}
                          >
                            Rozpocznij
                          </button>
                          <Popup />
                          <Link to={`/orders/${orderId}/production-materials`}>
                            <button
                              className="btn btn-danger"
                              id="gettingStartedBtn"
                              type="button"
                              tabIndex={-1}
                            >
                              Pokaż kartę
                            </button>
                          </Link>
                          <button
                            className="btn btn-danger"
                            id="gettingStartedBtnRecalc"
                            type="button"
                            onClick={recalculate}
                            tabIndex={-1}
                          >
                            Ponownie przelicz materiały
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-danger"
                            id="gettingStartedBtn"
                            type="submit"
                            tabIndex={-1}
                            onClick={handleOpen}
                          >
                            Rozpocznij
                          </button>
                          <Popup />
                        </>
                      )}
                    {calculationReady
                      ? (
                        <>
                          <Link to={`/production-materials-manually-calculated/${zamowieniaId}/show`}>
                            <button
                              className="btn btn-danger"
                              id="gettingStartedBtn"
                              type="button"
                              tabIndex={-1}
                            >
                              Pokaż kalkulację
                            </button>
                          </Link>
                        </>
                      ) : (
                        (urlReady
                            && (
                            <>
                              <button
                                className="btn btn-danger"
                                id="gettingStartedBtn"
                                type="button"
                                tabIndex={-1}
                                onClick={handleOpenUrl}
                              >
                                Dodaj link do pdf
                              </button>
                              <PopupURL />
                            </>
                            ))
                      )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

GettingStarted.propTypes = {
  addNextForm: PropTypes.func.isRequired,
  setOrderNumber: PropTypes.func.isRequired,
  // TODO
  // eslint-disable-next-line react/require-default-props
  orderNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default GettingStarted;
