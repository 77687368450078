import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import invoke from '../../lambda';
import FormActions from '../Input/FormActions';
import BasePlateResponse from './BasePlateResponse';

const BasePlate = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      color: form.values.color || '',
      extensionAmount: form.values.extensionAmount || '',
      widening: form.values.widening || '',
      mounting: form.values.mounting || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'Carport',
        type: 'basePlate',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`color__${form.id}`}>Kolor</label>
          <select
            className="form-control"
            id={`color__${form.id}`}
            onChange={formik.handleChange}
            name="color"
            value={formik.values.color}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value="MAGNELIS">MAGNELIS</option>
            <option value="RAL">RAL</option>
          </select>
        </div>
        {/* <div className="form-group">
          <label htmlFor={`extensionAmount__${form.id}`}>Rozbudowa (szt)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`extensionAmount__${form.id}`}
            name="extensionAmount"
            autoComplete="off"
            value={formik.values.extensionAmount}
            onChange={formik.handleChange}
            required
          />
        </div> */}
        <div className="form-group">
          <label htmlFor={`color__${form.id}`}>Poszerzenie</label>
          <select
            className="form-control"
            id={`widening__${form.id}`}
            onChange={formik.handleChange}
            name="widening"
            value={formik.values.widening}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value="TAK">TAK</option>
            <option value="NIE">NIE</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor={`mounting__${form.id}`}>Montaż</label>
          <select
            className="form-control"
            id={`mounting__${form.id}`}
            onChange={formik.handleChange}
            name="mounting"
            value={formik.values.mounting}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value="CEOWNIKI NA MARKACH">Ceowniki na markach</option>
            <option value="CEOWNIKI DO WBETONOWANIA">Ceowniki do wbetonowania</option>
          </select>
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <BasePlateResponse values={form.values} response={form.response} isPdf={false} />}
    </form>
  );
};

BasePlate.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default BasePlate;
