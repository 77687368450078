import React from 'react';

const GatewayWicketLong = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`gatewayWicketLong__${formId}`}>Oporek długi</label>
    <select
      className="form-control"
      id={`gatewayWicketLong__${formId}`}
      name="gatewayWicketLong"
      onChange={onChange}
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value="yes">TAK</option>
      <option value="no">NIE</option>
    </select>
  </div>
);

export default GatewayWicketLong;
