import React from 'react';

export const POLE_TYPE_HINGED = 'hinged';
export const POLE_TYPE_LOCKING = 'locking';

const PoleType = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`poleType__${formId}`}>Rodzaj słupa</label>
    <select
      className="form-control"
      id={`poleType__${formId}`}
      name="poleType"
      onChange={onChange}
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={POLE_TYPE_HINGED}>Zawiasowy</option>
      <option value={POLE_TYPE_LOCKING}>Domykowy</option>
    </select>
  </div>
);

export default PoleType;
